/* eslint no-unused-vars: 0  */
// Dependencies
//
// This will be outputted as a separate bundle from the main WE library.
//
// Note on specific dependencies:
// - jQuery is being loaded from a file so that we can set its global variables
//   appropriately. Rollup processes all imports before anything, so if we tried
//   to set the global variables in this file, the jQuery plugins would not see
//   that jQuery is available.
//
// What about styles?
// - We can import CSS directly from NPM in our main SCSS import file.
import './config/jqueryLoad';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import 'magnific-popup/dist/jquery.magnific-popup.min';
import 'slick-carousel/slick/slick.min';
// import ScrollMagic from 'scrollmagic';
// import { TweenMax, TimelineMax, Power1 } from 'gsap/TweenMax';
// import 'scrollmagic/scrollmagic/minified/plugins/animation.gsap.min';
import Cookies from 'js-cookie';

// Declare globals
// window.TweenMax = TweenMax;
// window.TimelineMax = TimelineMax;
// window.Power1 = Power1;

// window.ScrollMagic = ScrollMagic;
window.Cookies = Cookies;
